// ordering level reducer
import { get, union } from 'lodash';
import {
  CLEAR_SELECTED_TABLE,
  CLEAR_UPDATED_SCAN_CODE,
  DISABLED_MENU_ITEMS,
  DISPATCH_CUSTOMIZATION_LOADER,
  DISPATCH_RESTAURANT_GROUP,
  DISPATCH_SELECTED_ADDRESS,
  FETCH_AVAILABLE_OFFERS,
  FETCH_GUEST_ORDERS,
  FETCH_MENU_ITEM_CUSTOMIZATIONS,
  FETCH_ORDERED_ITEMS,
  FETCH_ORDER_STATUS,
  FETCH_RESTAURANT_DATA,
  FETCH_RESTAURANT_MENU,
  FETCH_SELECTED_TABLE,
  FETCH_VIEW_CART,
  PLACE_ORDER,
  PREVIOUS_SELECTED_TABLE,
  UPDATE_MENU,
  FETCH_RECENTLY_VISITED_RESTAURANT,
  FETCH_GUEST_PREVIOUS_ORDERED_ITEMS,
  CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS,
  FETCH_GUEST_ORDER_COUNT,
  FETCH_MENU_ITEM_VARIATIONS,
  CLEAR_SELECTED_ADDRESS,
  UPDATE_GUEST_ADDRESSES,
  ADD_GUEST_ADDRESS,
  DELETE_GUEST_ADDRESS,
  DISPATCH_UPDATED_GUEST_ADDRESSES,
  FETCH_GUEST_TABLE_RESERVATIONS,
} from '../../actions/ordering/actionType';
import { GET_ORDER_FEEDBACK_RESPONSE } from '../../actions/actionType';

const ordering = (state = {}, action = {}) => {
  if (action.type === FETCH_RESTAURANT_MENU) {
    const {
      restaurant,
      selectedTable,
      serviceTypes,
      sections,
      disabledCategories,
      recommendedSections,
    } = action;
    return {
      ...state,
      menu: union(recommendedSections, sections),
      restaurant,
      selectedTable,
      serviceTypes,
      disabledCategories,
      recommendedSections,
    };
  } else if (action.type === DISPATCH_RESTAURANT_GROUP) {
    const { restaurantGroup } = action;
    return {
      ...state,
      restaurantGroup,
    };
  } else if (action.type === FETCH_RESTAURANT_DATA) {
    const { restaurant } = action;
    return {
      ...state,
      restaurant,
    };
  } else if (action.type === UPDATE_MENU) {
    const { menu } = action;
    return {
      ...state,
      searchResult: menu,
    };
  } else if (action.type === FETCH_MENU_ITEM_CUSTOMIZATIONS) {
    const { customization } = action;
    return {
      ...state,
      customization,
    };
  } else if (action.type === FETCH_VIEW_CART) {
    const { items } = action;
    return {
      ...state,
      viewCart: items,
    };
  } else if (action.type === PLACE_ORDER) {
    const { orderedItems } = action;
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === FETCH_ORDER_STATUS) {
    let orderedItems = get(state, 'orderedItems', {});
    const { orderStatus } = action;
    orderedItems.statusCode = get(orderStatus, 'status', '');
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === FETCH_ORDERED_ITEMS) {
    const { orderedItems } = action;
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === ADD_GUEST_ADDRESS) {
    const { addressObjBody } = action;
    return {
      ...state,
      guestAddresses: Array.isArray(state.guestAddresses)
        ? [...state.guestAddresses, addressObjBody]
        : [addressObjBody],
    };
  } else if (action.type === DELETE_GUEST_ADDRESS) {
    const { guestAddressBodyid } = action;
    const updatedGuestAddresses = state.guestAddresses.filter(
      (address) => address.id !== guestAddressBodyid,
    );
    return {
      ...state,
      guestAddresses: updatedGuestAddresses,
      selectedAddress: {},
    };
  } else if (action.type === UPDATE_GUEST_ADDRESSES) {
    const { guestAddresses } = action;
    return {
      ...state,
      guestAddresses,
    };
  } else if (action.type === DISPATCH_UPDATED_GUEST_ADDRESSES) {
    const { guestAddress } = action;
    const updatedGuestAddresses = state.guestAddresses.map((address) => {
      if (address.id === guestAddress.id) {
        return guestAddress;
      }
      return address;
    });
    return {
      ...state,
      guestAddresses: updatedGuestAddresses,
      selectedAddress: {},
    };
  } else if (action.type === CLEAR_SELECTED_TABLE) {
    const { selectedTable } = action;
    return {
      ...state,
      selectedTable,
    };
  } else if (action.type === CLEAR_SELECTED_ADDRESS) {
    return {
      ...state,
      selectedAddress: {},
    };
  } else if (action.type === CLEAR_UPDATED_SCAN_CODE) {
    const { updatedScanCode } = action;
    return {
      ...state,
      updatedScanCode,
    };
  } else if (action.type === FETCH_GUEST_ORDERS) {
    const { guestOrders } = action;
    return {
      ...state,
      guestOrders,
    };
  } else if (action.type === DISABLED_MENU_ITEMS) {
    const { disabledItems } = action;
    return {
      ...state,
      disabledItems,
    };
  } else if (action.type === FETCH_SELECTED_TABLE) {
    const { selectedTable } = action;
    return {
      ...state,
      selectedTable,
    };
  } else if (action.type === PREVIOUS_SELECTED_TABLE) {
    const { previousSelectedTable } = action;
    return {
      ...state,
      previousSelectedTable,
    };
  } else if (action.type === DISPATCH_SELECTED_ADDRESS) {
    const { selectedAddress } = action;
    return {
      ...state,
      selectedAddress,
    };
  } else if (action.type === DISPATCH_CUSTOMIZATION_LOADER) {
    const { showCustomizationLoader } = action;
    return {
      ...state,
      showCustomizationLoader,
    };
  } else if (action.type === FETCH_AVAILABLE_OFFERS) {
    const { availableOffers } = action;
    return {
      ...state,
      availableOffers,
    };
  } else if (action.type === FETCH_RECENTLY_VISITED_RESTAURANT) {
    const { recentlyVisitedOutlets } = action;
    return { ...state, recentlyVisitedOutlets };
  } else if (action.type === FETCH_GUEST_PREVIOUS_ORDERED_ITEMS) {
    const { guestPreviousOrderedItems } = action;
    return { ...state, guestPreviousOrderedItems };
  } else if (action.type === CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS) {
    return {
      ...state,
      guestPreviousOrderedItems: [],
    };
  } else if (action.type === FETCH_GUEST_ORDER_COUNT) {
    const { orderCount } = action;
    return { ...state, orderCount };
  } else if (action.type === FETCH_MENU_ITEM_VARIATIONS) {
    const { variations } = action;
    return {
      ...state,
      variations,
    };
  } else if (action.type === GET_ORDER_FEEDBACK_RESPONSE) {
    const { orderFeedbackResponse } = action;
    return {
      ...state,
      orderFeedbackResponse,
    };
  } else if (action.type === FETCH_GUEST_TABLE_RESERVATIONS) {
    const { guestTableReservations } = action;
    return { ...state, guestTableReservations };
  }
  return state;
};

export default ordering;
