export const foodItemType = {
  veg: { name: 'VEGETARIAN', code: 'VF' },
  nonVeg: { name: 'NON_VEGETARIAN', code: 'DF' },
  egg: { name: 'EGG', code: '' },
  all: { name: 'ALL', code: 'ALL' },
};

export const foodItemTypeDropdownOptions = [
  { title: foodItemType.all.name, value: foodItemType.all.code },
  { title: foodItemType.veg.name, value: foodItemType.veg.code },
  { title: foodItemType.nonVeg.name, value: foodItemType.nonVeg.code },
];

export const nativeEventType = {
  openScanner: 'open-scanner',
  closeScanner: 'close-scanner',
  enterCode: 'enter-code',
  permissions: 'permissions',
  scannedCode: 'scanned-code',
};

export const extraDiscountCode = 'OUTLETDISCOUNT';

export const tagTypes = {
  pointCredit: 'point_credit',
};
