import {
  fetchGuestOrdersApi,
  fetchMenuItemCustomizationsApi,
  fetchOrderedItemsApi,
  fetchRestaurantDataApi,
  fetchRestaurantMenuApi,
  fetchViewCartItemsApi,
  getOrderStatusApi,
  placeOrderApi,
  fetchRecentlyVisitedRestaurant,
  fetchGuestPreviousOrderedItemsApi,
  fetchSelectedTableApi,
  getCurrentOrderCountApi,
  fetchMenuItemVariationsApi,
  fetchGuestAddressesDataApi,
  addGuestAddressApi,
  deleteGuestAddressApi,
  fetchGuestTableReservationsApi,
} from '../../api/api';
import {
  DISABLED_MENU_ITEMS,
  DISPATCH_CUSTOMIZATION_LOADER,
  DISPATCH_RESTAURANT_GROUP,
  FETCH_AVAILABLE_OFFERS,
  FETCH_MENU_ITEM_CUSTOMIZATIONS,
  FETCH_ORDERED_ITEMS,
  FETCH_ORDER_STATUS,
  FETCH_RESTAURANT_DATA,
  FETCH_RESTAURANT_MENU,
  FETCH_VIEW_CART,
  PLACE_ORDER,
  UPDATE_MENU,
  FETCH_GUEST_ORDERS,
  FETCH_RECENTLY_VISITED_RESTAURANT,
  FETCH_GUEST_PREVIOUS_ORDERED_ITEMS,
  CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS,
  CLEAR_SELECTED_TABLE,
  FETCH_SELECTED_TABLE,
  FETCH_GUEST_ORDER_COUNT,
  FETCH_MENU_ITEM_VARIATIONS,
  CLEAR_SELECTED_ADDRESS,
  UPDATE_GUEST_ADDRESSES,
  ADD_GUEST_ADDRESS,
  DELETE_GUEST_ADDRESS,
  DISPATCH_UPDATED_GUEST_ADDRESSES,
  DISPATCH_SELECTED_ADDRESS,
  FETCH_GUEST_TABLE_RESERVATIONS,
} from './actionType';
import { get, isEmpty } from 'lodash';
import { extractWhitelabelSubdomain } from '../../utils/helpers';
import {
  apiErrorResponseAction,
  beginAjaxCallAction,
  endAjaxCallAction,
} from '../../actions/common';
import { BEGIN_AJAX_CALL, END_AJAX_CALL } from '../../actions/common/actionType';

export const fetchRestaurantMenuAction =
  (restaurantAndTableId, includeMenu) => async (dispatch) => {
    let restaurantMenuItems = {};
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const subDomain = extractWhitelabelSubdomain();
    const url = `/restaurants/scan/${restaurantAndTableId}?includeMenu=${includeMenu}${
      subDomain ? '&subDomain=' + subDomain : ''
    }`;
    await fetchRestaurantMenuApi(url)
      .then((response) => {
        restaurantMenuItems = get(response, 'data', {});
        const {
          restaurantGroup,
          restaurant,
          selectedTable,
          serviceTypes,
          sections,
          disabledCategories,
          recommendedSections,
        } = restaurantMenuItems;
        if (!isEmpty(restaurantGroup)) {
          dispatch({
            type: DISPATCH_RESTAURANT_GROUP,
            restaurantGroup,
          });
        }
        dispatch({
          type: FETCH_RESTAURANT_MENU,
          restaurant,
          selectedTable,
          serviceTypes,
          sections,
          disabledCategories,
          recommendedSections,
        });
        // TODO: Need to revist the same in future
        // invalidate previous cart selection and payment related data
        // dispatch(clearViewCartItems());
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        restaurantMenuItems = get(error, 'response.data', {});
      })
      .finally(() => {
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        });
      });
    return restaurantMenuItems;
  };

export const clearRestaurantGroupDataAction = () => async (dispatch) => {
  dispatch({
    type: DISPATCH_RESTAURANT_GROUP,
    restaurantGroup: {},
  });
};

export const invalidateRestaurantDataAction = () => async (dispatch) => {
  dispatch({
    type: FETCH_RESTAURANT_MENU,
    restaurant: {},
    selectedTable: {},
    serviceTypes: [],
    sections: {},
    disabledCategories: {},
    recommendedSections: [],
    restaurantOffers: [],
  });
};

export const fetchRestaurantDataAction =
  ({ restaurantId, depthParam }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BEGIN_AJAX_CALL,
        showSpinner: true,
      });
      const restaurant = await fetchRestaurantDataApi({ restaurantId, depthParam })
        .then((restaurantDataResponse) => {
          dispatch({
            type: FETCH_RESTAURANT_DATA,
            restaurant: get(restaurantDataResponse, 'data', {}),
          });
          return restaurantDataResponse;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return restaurant;
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: END_AJAX_CALL,
        showSpinner: false,
      });
    }
  };

export const updateMenuAction = (menu) => async (dispatch) => {
  dispatch({
    type: UPDATE_MENU,
    menu,
  });
};

export const fetchMenuItemCustomizationsAction = (restaurantId, menuItemId) => async (dispatch) => {
  try {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: true,
    });
    const customizationResponse = await fetchMenuItemCustomizationsApi({ restaurantId, menuItemId })
      .then((response) => {
        dispatch({
          type: FETCH_MENU_ITEM_CUSTOMIZATIONS,
          customization: get(response, 'data.menuCustomizationGroups', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return customizationResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: false,
    });
  }
};

export const clearPreviousCustomization = () => async (dispatch) => {
  dispatch({
    type: FETCH_MENU_ITEM_CUSTOMIZATIONS,
    customization: {},
  });
};

export const clearViewCartItems = () => async (dispatch) => {
  dispatch({
    type: FETCH_VIEW_CART,
    items: {},
  });
};

export const updateCartItemsAction = (response) => async (dispatch) => {
  dispatch({
    type: FETCH_VIEW_CART,
    items: response,
  });
};

export const fetchViewCartItemsAction = (cartItems) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const viewCartItemsResponse = await fetchViewCartItemsApi(cartItems)
      .then((response) => {
        dispatch({
          type: FETCH_VIEW_CART,
          items: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        dispatch(
          dispatchDisabledMenuItems(get(errorResponse, 'response.data.data.disabledItems', [])),
        );
        return get(errorResponse, 'response', {});
      });
    return viewCartItemsResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const placeOrderAction = (orderItems) => async (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const placeOrderAPIResponse = await placeOrderApi(orderItems)
      .then((response) => {
        const orderedItems = get(response, 'data', {});
        dispatch({
          type: PLACE_ORDER,
          orderedItems,
        });

        return response;
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        dispatch(dispatchDisabledMenuItems(get(error, 'response.data.data.disabledItems', [])));
        return get(error, 'response', {});
      });

    return placeOrderAPIResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const fetchAvailableOffersAction =
  ({ offersReqBody }) =>
  (dispatch) => {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });

    const cartItemsAPIResponse = fetchViewCartItemsApi(offersReqBody)
      .then((response) => {
        dispatch({
          type: FETCH_AVAILABLE_OFFERS,
          availableOffers: get(response, 'data.offers', []),
        });
        return response;
      })
      .catch((error) => {
        dispatch(apiErrorResponseAction(get(error, 'response.data', {})));
        return get(error, 'response', {});
      })
      .finally(() => {
        dispatch({
          type: END_AJAX_CALL,
          showSpinner: false,
        });
      });

    return cartItemsAPIResponse;
  };

export const getOrderStatus = (orderId) => async (dispatch) => {
  const orderStatus = await getOrderStatusApi(orderId);
  dispatch({
    type: FETCH_ORDER_STATUS,
    orderStatus,
  });

  return orderStatus;
};

export const fetchOrderedItemsAction = (orderId) => (dispatch) => {
  try {
    dispatch({
      type: BEGIN_AJAX_CALL,
      showSpinner: true,
    });
    const offeredItems = fetchOrderedItemsApi(orderId)
      .then((offeredItemsResponse) => {
        dispatch({
          type: FETCH_ORDERED_ITEMS,
          orderedItems: get(offeredItemsResponse, 'data', {}),
        });
        return offeredItemsResponse;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });
    return offeredItems;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: END_AJAX_CALL,
      showSpinner: false,
    });
  }
};

export const clearFetchOrderedItemsAction = () => (dispatch) => {
  dispatch({
    type: FETCH_ORDERED_ITEMS,
    orderedItems: {},
  });
};

export const dispatchDisabledMenuItems = (disabledItems) => async (dispatch) => {
  dispatch({
    type: DISABLED_MENU_ITEMS,
    disabledItems,
  });
};

export const clearDisabledMenuItems = () => async (dispatch) => {
  dispatch({
    type: DISABLED_MENU_ITEMS,
    disabledItems: [],
  });
};

export const fetchGuestPreviousOrderedItemsAction =
  ({ guestId, restaurantId, size }) =>
  async (dispatch) => {
    try {
      const guestPreviousOrderedItems = await fetchGuestPreviousOrderedItemsApi({
        guestId,
        restaurantId,
        size,
      })
        .then((response) => {
          dispatch({
            type: FETCH_GUEST_PREVIOUS_ORDERED_ITEMS,
            guestPreviousOrderedItems: get(response, 'data.menuItems', []),
          });
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return guestPreviousOrderedItems;
    } catch (error) {
      console.log(error);
    }
  };

export const clearGuestPreviousOrderedItemsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_PREVIOUS_ORDERED_ITEMS,
  });
};

export const fetchGuestOrdersAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      dispatch(beginAjaxCallAction());

      const guestOrdersResponse = await fetchGuestOrdersApi({ url })
        .then((response) => {
          const guestOrders = get(response, 'data', {});
          dispatch({
            type: FETCH_GUEST_ORDERS,
            guestOrders,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return guestOrdersResponse;
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(endAjaxCallAction());
    }
  };

export const fetchRecentlyVisitedRestaurantAction =
  (requestBody, withLoader) => async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());

      const recentlyVisitedOutletsResponse = await fetchRecentlyVisitedRestaurant(requestBody)
        .then((response) => {
          const recentlyVisitedOutlets = get(response, 'data.data', {});
          dispatch({
            type: FETCH_RECENTLY_VISITED_RESTAURANT,
            recentlyVisitedOutlets,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return recentlyVisitedOutletsResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchSelectedTableAction = (locationId) => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
  let selectedTable = await fetchSelectedTableApi(locationId);
  dispatch({
    type: FETCH_SELECTED_TABLE,
    selectedTable,
  });
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const dispatchAddedGuestAddressAction = (addressObjBody) => async (dispatch) => {
  dispatch({
    type: ADD_GUEST_ADDRESS,
    addressObjBody: addressObjBody.guestAddresses[0],
  });
};

export const dispatchUpdatedGuestAddressAction = (guestAddress) => async (dispatch) => {
  dispatch({
    type: DISPATCH_UPDATED_GUEST_ADDRESSES,
    guestAddress: guestAddress,
  });
};

export const updateGuestAddressesAction = (guestAddressId) => async (dispatch) => {
  dispatch({
    type: DELETE_GUEST_ADDRESS,
    guestAddressBodyid: guestAddressId,
  });
};

export const addGuestAddressAction = (addressObjBody) => async (dispatch) => {
  try {
    const addGuestAddressResponse = await addGuestAddressApi(addressObjBody);
    dispatch(dispatchAddedGuestAddressAction(addressObjBody));
    return addGuestAddressResponse;
  } catch (error) {
    console.error(error);
  }
};

export const updateGuestAddressAction = (addressObjBody) => async (dispatch) => {
  try {
    const updateGuestAddressResponse = await addGuestAddressApi(addressObjBody);
    const guestAddressBody = get(addressObjBody, 'guestAddresses[0]', {});
    dispatch(dispatchUpdatedGuestAddressAction(guestAddressBody));
    return updateGuestAddressResponse;
  } catch (error) {
    console.error(error);
  }
};

export const deleteGuestAddressAction = (guestAddressBody) => async (dispatch) => {
  try {
    const deleteGuestAddressResponse = await deleteGuestAddressApi(guestAddressBody);
    const { guestAddresses } = guestAddressBody;
    const guestAddressId = get(guestAddresses, '[0].id', '');
    dispatch(updateGuestAddressesAction(guestAddressId));
    return deleteGuestAddressResponse;
  } catch (error) {
    console.error(error);
  }
};

export const fetchGuestAddressesDataAction =
  ({ guestId, brandId }) =>
  async (dispatch) => {
    try {
      let response = await fetchGuestAddressesDataApi({ guestId, brandId });
      let guestAddressesData = response.data.guestAddresses;

      dispatch({
        type: UPDATE_GUEST_ADDRESSES,
        guestAddresses: guestAddressesData,
      });

      return guestAddressesData;
    } catch (error) {
      console.error('Error fetching guest addresses:', error);
      throw error;
    }
  };

export const clearSelectedTable = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_TABLE,
    selectedTable: {},
  });
};

export const dispatchSelectedAddressAction = (selectedAddress) => async (dispatch) => {
  dispatch({
    type: DISPATCH_SELECTED_ADDRESS,
    selectedAddress,
  });
};

export const clearSelectedAddress = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_ADDRESS,
  });
};

export const fetchCurrentOrderCountAction = (requestBody, withLoader) => async (dispatch) => {
  try {
    withLoader && dispatch(beginAjaxCallAction());

    const currentOrderCountResponse = await getCurrentOrderCountApi(requestBody)
      .then((response) => {
        dispatch({
          type: FETCH_GUEST_ORDER_COUNT,
          orderCount: get(response, 'data.orderCount', 0),
        });

        return response;
      })
      .catch((errorResponse) => {
        dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
        return get(errorResponse, 'response', {});
      });

    return currentOrderCountResponse;
  } catch (err) {
    console.error(err);
  } finally {
    withLoader && dispatch(endAjaxCallAction());
  }
};

export const fetchMenuItemVariationsAction = (restaurantId, menuItemId) => async (dispatch) => {
  try {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: true,
    });
    const variationsResponse = await fetchMenuItemVariationsApi({ restaurantId, menuItemId })
      .then((response) => {
        dispatch({
          type: FETCH_MENU_ITEM_VARIATIONS,
          variations: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });
    return variationsResponse;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: DISPATCH_CUSTOMIZATION_LOADER,
      showCustomizationLoader: false,
    });
  }
};

export const clearPreviousVariations = () => async (dispatch) => {
  dispatch({
    type: FETCH_MENU_ITEM_VARIATIONS,
    variations: {},
  });
};

export const fetchGuestTableReservationsAction = (payload) => async (dispatch) => {
  try {
    dispatch(beginAjaxCallAction());
    const guestTableReservationsResponse = await fetchGuestTableReservationsApi(payload)
      .then((response) => {
        const guestTableReservations = get(response, 'data', {});
        dispatch({
          type: FETCH_GUEST_TABLE_RESERVATIONS,
          guestTableReservations,
        });

        return response;
      })
      .catch((errorResponse) => {
        return get(errorResponse, 'response', {});
      });

    return guestTableReservationsResponse;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(endAjaxCallAction());
  }
};
