import { get } from 'lodash';
import store from '../../store/store';
import {
  FETCH_REWARD_PROGRAM_RULES,
  FETCH_BRAND_DEAL_DATA,
  CLEAR_BRAND_DEAL_DATA,
  FETCH_BRAND_OUTLETS,
  FETCH_GUEST_COUPONS,
  FETCH_REWARD_POINTS_TRANSACTIONS,
  BRAND_REWARD_GUEST_DETAILS,
  FETCH_REWARD_DEALS,
  CLEAR_GUEST_COUPONS,
  FETCH_REWARD_POINTS,
  UPDATE_GUEST_COUPON_FILTER,
  UPDATE_REWARD_POINT_TRANSACTION_FILTER,
  DISPATCH_CALLBACK_URL,
  SIGNUP_DETAILS,
  FETCH_VIP_PASSES,
  FETCH_VIP_PASS_DATA,
  FETCH_VIP_PASS_COUPONS,
  CLEAR_VIP_PASS_DATA,
  FETCH_PASS_MEMBERSHIP_BY_ID,
  SUBSCRIBE_PASS_MEMBERSHIP,
  FETCH_PASS_MEMBERSHIPS,
  CLEAR_PASS_MEMBERSHIPS,
  DISPATCH_SUBSCRIBED_MEMBERSHIP,
  FETCH_ALL_SUB_BRANDS,
  UPDATE_PAY_EARN_DATA,
  UPDATE_BOOK_TABLE_DATA,
  FETCH_GUEST_BOGO_COUPONS,
  FETCH_GUEST_LAST_ORDER,
} from './actionType';
import { BEGIN_AJAX_CALL, END_AJAX_CALL } from '../actionType';

import { apiErrorResponseAction } from '../app.action';

import {
  fetchRewardProgramRulesApi,
  fetchDealByIdApi,
  fetchOutletDetailsApi,
  fetchGuestCouponsApi,
  fetchRewardPointsTransactionsApi,
  updateRewardGuestProfileApi,
  fetchRewardGuestDetailsApi,
  fetchBrandDealsApi,
  fetchRewardPointsApi,
  initiateDeleteAccountApi,
  initiateDeleteBrandGuestAccountApi,
  fetchVIPPassesApi,
  fetchVIPPassByIdApi,
  fetchVIPPassCouponsByIdApi,
  fetchPassMembershipByIdApi,
  subscribePassMembershipApi,
  fetchPassMembershipsApi,
  fetchAllSubBrandsApi,
  updatePaymentCartApi,
  paymentOrderPayApi,
  paymentOrderDetailsApi,
  fetchReservationAvailableSlotsApi,
  createTableReservationApi,
  fetchTableReservationDetailsApi,
  fetchGuestLastOrderApi,
} from '../../api/api';
import { isNativeWebView } from '../../utils/helpers';

export const beginAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: BEGIN_AJAX_CALL,
    showSpinner: true,
  });
};

export const endAjaxCallAction = () => async (dispatch) => {
  dispatch({
    type: END_AJAX_CALL,
    showSpinner: false,
  });
};

export const fetchGuestCouponsAction =
  ({ url, dispatchBogoCoupons }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const guestCouponsResponse = await fetchGuestCouponsApi({ url })
        .then((response) => {
          dispatchBogoCoupons
            ? dispatch({
                type: FETCH_GUEST_BOGO_COUPONS,
                guestBogoCoupons: get(response, 'data', {}),
              })
            : dispatch({
                type: FETCH_GUEST_COUPONS,
                guestCoupons: get(response, 'data', {}),
              });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return guestCouponsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const checkGuestCouponsAction =
  ({ url }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const guestCouponsResponse = await fetchGuestCouponsApi({ url })
        .then((response) => {
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return guestCouponsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const clearGuestCouponsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_GUEST_COUPONS,
    guestCoupons: {},
  });
};

export const fetchRewardProgramRulesAction =
  ({ brandId }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const rewardProgramResponse = await fetchRewardProgramRulesApi({ brandId })
        .then((response) => {
          dispatch({
            type: FETCH_REWARD_PROGRAM_RULES,
            rewardRules: get(response, 'data', {}),
          });
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return rewardProgramResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchDealByIdAction =
  ({ dealCode, guestId }) =>
  async (dispatch) => {
    try {
      const brandDealDetailResponse = await fetchDealByIdApi({ dealCode, guestId })
        .then((response) => {
          const brandDealDetail = get(response, 'data', {});
          dispatch({
            type: FETCH_BRAND_DEAL_DATA,
            brandDealDetail,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return brandDealDetailResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const clearBrandDealDataAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_BRAND_DEAL_DATA,
  });
};

export const fetchBrandOutletsAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      const brandOutletsResponse = await fetchOutletDetailsApi({ url })
        .then((response) => {
          const brandOutlets = get(response, 'data', {});
          dispatch({
            type: FETCH_BRAND_OUTLETS,
            brandOutlets,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return brandOutletsResponse;
    } catch (err) {
      console.error(err);
    }
  };

export const fetchRewardPointsTransactionAction =
  (requestBody, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const rewardPointsTransactionResponse = await fetchRewardPointsTransactionsApi(requestBody)
        .then((response) => {
          dispatch({
            type: FETCH_REWARD_POINTS_TRANSACTIONS,
            rewardPointTransactions: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return rewardPointsTransactionResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const dispatchRewardGuestProfileAction = (data) => async (dispatch) => {
  dispatch({
    type: BRAND_REWARD_GUEST_DETAILS,
    rewardGuestDetails: data,
  });
};

export const updateRewardGuestProfileAction =
  (updateProfileReqBody, withLoader = true, sendEventToNative) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const brandRewardGuestProfileResponse = await updateRewardGuestProfileApi(
        updateProfileReqBody,
      )
        .then((response) => {
          dispatch({
            type: BRAND_REWARD_GUEST_DETAILS,
            rewardGuestDetails: get(response, 'data', {}),
          });
          if (sendEventToNative && isNativeWebView) {
            let loginData = store.store.getState().authentication.loginData;
            let authData = store.store.getState().authentication.authData;

            const data = {
              eventType: {
                type: 'loginData',
                data: {
                  guest: loginData,
                  auth: authData,
                  rewardGuestDetails: get(response, 'data', {}),
                },
              },
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
          }
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return brandRewardGuestProfileResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchRewardGuestDetailsAction =
  (requestBody, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const rewardGuestDetailsResponse = await fetchRewardGuestDetailsApi(requestBody)
        .then((response) => {
          dispatch({
            type: BRAND_REWARD_GUEST_DETAILS,
            rewardGuestDetails: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return rewardGuestDetailsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchRewardDealsAction =
  ({ url }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const rewardDealsResponse = await fetchBrandDealsApi({ url })
        .then((response) => {
          dispatch({
            type: FETCH_REWARD_DEALS,
            rewardDeals: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return rewardDealsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchRewardPointsAction =
  ({ url }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const rewardPointsResponse = await fetchRewardPointsApi({ url })
        .then((response) => {
          dispatch({
            type: FETCH_REWARD_POINTS,
            rewardPoints: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return rewardPointsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const initiateDeleteAccountAction =
  (requestBody, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const deleteAccountAPIResponse = await initiateDeleteAccountApi(requestBody)
        .then((response) => response)
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });

      return deleteAccountAPIResponse;
    } catch (err) {
      console.error(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const initiateDeleteBrandGuestAccountAction =
  (requestBody, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const deleteRewardsAccountResponse = await initiateDeleteBrandGuestAccountApi(requestBody)
        .then((response) => response)
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response.data', {});
        });
      return deleteRewardsAccountResponse;
    } catch (err) {
      console.log(err);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const updateGuestCouponFilterAction = (requestBody) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_GUEST_COUPON_FILTER,
      filter: get(requestBody, 'filter', {}),
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateRewardPointTransactionFilterAction = (requestBody) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_REWARD_POINT_TRANSACTION_FILTER,
      filter: get(requestBody, 'filter', {}),
    });
  } catch (error) {
    console.log(error);
  }
};

export const dispatchCallbackUrlAction =
  ({ callbackUrl }) =>
  async (dispatch) => {
    dispatch({
      type: DISPATCH_CALLBACK_URL,
      callbackUrl,
    });
  };

export const updateSignupDetailsAction = (signupDetails) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_DETAILS, signupDetails });
  } catch (error) {
    console.log(error);
  }
};

export const fetchVIPPassesAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      const vipPassesResponse = await fetchVIPPassesApi({ url })
        .then((response) => {
          const vipPasses = get(response, 'data', {});
          dispatch({
            type: FETCH_VIP_PASSES,
            vipPasses,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return vipPassesResponse;
    } catch (err) {
      console.error(err);
    }
  };

export const fetchVIPPassByIdAction =
  ({ passCode, brandGuestId }) =>
  async (dispatch) => {
    try {
      const vipPassDetailResponse = await fetchVIPPassByIdApi({ passCode, brandGuestId })
        .then((response) => {
          const vipPassDetail = get(response, 'data', {});
          dispatch({
            type: FETCH_VIP_PASS_DATA,
            vipPassDetail,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return vipPassDetailResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const fetchVIPPassCouponsByIdAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      const vipPassCouponsResponse = await fetchVIPPassCouponsByIdApi({ url })
        .then((response) => {
          const vipPassCoupons = get(response, 'data', {});
          dispatch({
            type: FETCH_VIP_PASS_COUPONS,
            vipPassCoupons,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return vipPassCouponsResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const clearVIPPassDataAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_VIP_PASS_DATA,
  });
};

export const fetchPassMembershipsAction =
  ({ url }) =>
  async (dispatch) => {
    try {
      const passMembershipsResponse = await fetchPassMembershipsApi({ url })
        .then((response) => {
          const passMemberships = get(response, 'data', {});
          dispatch({
            type: FETCH_PASS_MEMBERSHIPS,
            passMemberships,
          });

          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return passMembershipsResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const clearPassMembershipsAction = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PASS_MEMBERSHIPS,
  });
};

export const fetchPassMembershipByIdAction =
  ({ membershipId, depth }) =>
  async (dispatch) => {
    try {
      const passMembershipResponse = await fetchPassMembershipByIdApi({ membershipId, depth })
        .then((response) => {
          const passMembership = get(response, 'data', {});
          dispatch({
            type: FETCH_PASS_MEMBERSHIP_BY_ID,
            passMembership,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return passMembershipResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const subscribePassMembershipAction =
  ({ subscribePassMembershipReqBody }) =>
  async (dispatch) => {
    try {
      const subscribedPassMembershipResponse = await subscribePassMembershipApi({
        subscribePassMembershipReqBody,
      })
        .then((response) => {
          const subscribedMembership = get(response, 'data', {});
          dispatch({
            type: DISPATCH_SUBSCRIBED_MEMBERSHIP,
            subscribedMembership,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return subscribedPassMembershipResponse;
    } catch (err) {
      console.log(err);
    }
  };

export const fetchAllSubBrandAction = (payload) => async (dispatch) => {
  try {
    const subBrandsResponse = await fetchAllSubBrandsApi(payload)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SUB_BRANDS,
          subBrands: get(response, 'data', {}),
        });
        return response;
      })
      .catch((errorResponse) => {
        return errorResponse;
      });
    return subBrandsResponse;
  } catch (error) {
    console.log(error);
  }
};

export const updatePaymentCartAction =
  (payload, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const paymentOrderResponse = await updatePaymentCartApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_PAY_EARN_DATA,
            cart: { ...payload, ...get(response, 'data', {}) },
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return errorResponse;
        });

      return paymentOrderResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const updatePayEarnDataAction = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PAY_EARN_DATA,
      payEarn: { ...payload },
    });
  } catch (error) {
    console.log(error);
  }
};

export const paymentOrderPayAction =
  (payload, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const paymentOrderPayResponse = await paymentOrderPayApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_PAY_EARN_DATA,
            order: get(response, 'data', {}),
            cart: payload,
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return errorResponse;
        });

      return paymentOrderPayResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const paymentOrderDetailsAction =
  (payload, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const paymentOrderPayResponse = await paymentOrderDetailsApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_PAY_EARN_DATA,
            order: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return errorResponse;
        });

      return paymentOrderPayResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchReservationAvailableSlotsAction =
  (payload, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const availableSlotsResponse = await fetchReservationAvailableSlotsApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_BOOK_TABLE_DATA,
            slots: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return errorResponse;
        });

      return availableSlotsResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const createTableReservationAction =
  (payload) =>
  async (dispatch, withLoader = true) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());

      const tableReservationResponse = await createTableReservationApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_BOOK_TABLE_DATA,
            booking: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          return get(errorResponse, 'response', {});
        });
      return tableReservationResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const fetchTableReservationDetailsAction =
  (payload, withLoader = true) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());

      const tableReservationResponse = await fetchTableReservationDetailsApi(payload)
        .then((response) => {
          dispatch({
            type: UPDATE_BOOK_TABLE_DATA,
            order: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return errorResponse;
        });

      return tableReservationResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

export const updateBookTableDataAction = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_BOOK_TABLE_DATA,
      bookTable: { ...payload },
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchGuestLastOrderAction =
  ({ url }, withLoader = false) =>
  async (dispatch) => {
    try {
      withLoader && dispatch(beginAjaxCallAction());
      const lastOrderResponse = await fetchGuestLastOrderApi({ url })
        .then((response) => {
          dispatch({
            type: FETCH_GUEST_LAST_ORDER,
            guestLastOrder: get(response, 'data', {}),
          });
          return response;
        })
        .catch((errorResponse) => {
          dispatch(apiErrorResponseAction(get(errorResponse, 'response.data', {})));
          return get(errorResponse, 'response', {});
        });
      return lastOrderResponse;
    } catch (error) {
      console.log(error);
    } finally {
      withLoader && dispatch(endAjaxCallAction());
    }
  };

