export const layoutConfiguration = [
  {
    version: 'L1',
    components: [
      { name: 'loyalty-card', version: 'cv1' },
      { name: 'outlet-list', version: 'cv2' },
      { name: 'recommended-menu-item-card', version: 'cv1' },
      { name: 'menu-item-card', version: 'cv1' },
    ],
  },
  {
    version: 'L2',
    components: [
      { name: 'loyalty-card', version: 'cv2' },
      { name: 'newly-launched-outlets', version: 'cv1' },
      { name: 'outlet-list', version: 'cv1' },
      { name: 'menu-category-section', version: 'cv1' },
      { name: 'recommended-menu-item-card', version: 'cv2' },
      { name: 'menu-item-card', version: 'cv2' },
    ],
  },
  { version: 'L3', components: [{ name: 'brand-promotion', version: 'cv1' }] },
];
