import orderingRoutes from '../../../routes/ordering';
import rewardsRoutes from '../../../routes/rewards';

export const navigationalPanelRoutes = [
  rewardsRoutes.containers.home,
  rewardsRoutes.containers.locations,
  rewardsRoutes.containers.account,
  rewardsRoutes.containers.ordering,
  `${orderingRoutes.containers.menu}/*`,
];

export const datePickerSettings = {
  openTo: 'year',
  views: ['year', 'month', 'day'],
  toolbarFormat: 'DD MMM YYYY',
};

export const orderTypes = {
  dineIn: 'DI',
  selfPickup: 'SP',
  delivery: 'DL',
};

export const rewardSignUpStates = {
  getStarted: 'GET_STARTED',
  enterNumber: 'ENTER_NUMBER',
  verifyNumber: 'VERIFY_NUMBER',
  enterDetails: 'ENTER_DETAILS',
  selectOutlet: 'SELECT_OUTLET',
};

export const rewardSigninStates = {
  enterNumber: 'ENTER_NUMBER',
  verifyNumber: 'VERIFY_NUMBER',
};

export const pointUnits = {
  percent: 'PER',
  absolute: 'ABS',
  item: 'ITM',
};

export const currencies = {
  INR: { text: 'Rs.', symbol: '₹' },
};

export const announcementBannerCarouselSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  lazyload: 'progressive', // accepts 'onDemand | progressive'
  variableWidth: true,
};

export const rewardCardsCarouselSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  lazyload: 'progressive', // accepts 'onDemand | progressive'
};

export const outletImagesCarouselSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  lazyload: 'progressive', // accepts 'onDemand | progressive'
};

export const rewardSettings = {
  home: 'HOME',
  profile: 'PROFILE',
  rewardHistory: 'REWARDS_HISTORY',
  rewardProgram: 'REWARDS_PROGRAM',
  settings: 'SETTINGS',
  favouriteLocation: 'FAVOURITE_LOCATION',
  helpSupport: 'HELP_SUPPORT',
  vipPass: 'VIP_PASS',
  myRewards: 'MY_REWARDS',
};

export const minAgeYearsForRewardOnboarding = 8;

export const rewardTransactionCategory = {
  credit: 'C',
  debit: 'D',
};

export const rewardSourceType = {
  order: 'ORDER',
  coupon: 'COUPON',
};

export const rewardTransactionType = {
  reward: 'REW',
  redeem: 'RED',
  payment: 'PYMT',
  default: 'DF',
};

export const accountHistoryView = {
  point: 'point-history',
  reward: 'reward-history',
  order: 'order-history',
};

export const rewardType = {
  added: 'Added',
  deducted: 'Deducted',
  adjusted: 'Adjusted',
};
const { BIRTHDAY_REWARD_PRE_MONTHS, ANNIVERSARY_REWARD_PRE_MONTHS } =
  '_env' in window && window._env;

export const birthdayRewardPreMonths = BIRTHDAY_REWARD_PRE_MONTHS;

export const anniversaryrewardPreMonths = ANNIVERSARY_REWARD_PRE_MONTHS;

export const dateFilterConfig = {
  filters: {
    default: { code: 'DF', title: 'All time' },
    custom: { code: 'C', title: 'Custom' },
    thisWeek: { code: 'TW', title: 'This week' },
    thisMonth: { code: 'TM', title: 'This month' },
    lastWeek: { code: 'LW', title: 'Last week' },
    lastMonth: { code: 'LM', title: 'Last month' },
  },

  filterDateFormat: 'DD MMM YYYY',
};

export const rewardPointFilter = [
  { title: 'All', code: 'ALL' },
  { title: 'Earned', code: 'EARNED' },
  { title: 'Used', code: 'USED' },
];

export const rewardFilter = [
  { title: 'All', code: 'ALL' },
  { title: 'Claimed', code: 'CLAIMED' },
  { title: 'Redeemed', code: 'REDEEMED' },
  { title: 'Expired', code: 'EXPIRED' },
];

export const rewardCategory = {
  bday: 'BDAY',
  rewardPoints: 'REWARD_COUPON',
  campaign: 'CM',
  reward: 'RW',
  welcome: 'WELCOME',
  pointOffer: 'REWARD_POINT',
  anniversary: 'ANNIVERSARY',
  tierUpgrade: 'TIER_UPGRADE',
};

export const pointPriceExcludeRewardCategories = [
  rewardCategory.bday,
  rewardCategory.welcome,
  rewardCategory.anniversary,
  rewardCategory.tierUpgrade,
  'FB',
];

export const nativeEventType = {
  appVersion: 'app-version',
  location: 'location',
  routeName: 'routeName',
  hardwareBackPress: 'hardwareBackPress',
  verifyPayment: 'verify-payment',
  appStartupInfo: 'appStartupInfo',
  nativeAppUpdateAvailable: 'nativeAppUpdateAvailable',
  disableKBAvoidingScroll: 'disable-kb-avoiding-scroll',
  disableKBAvoidingView: 'disable-kb-avoiding-view',
  navigateToPage: 'navigateToPage',
  brandGuestData: 'brandGuestData',
  resetState: 'resetState',
  exitPayEarn: 'exit-pay-earn',
  userLocation: 'user-location',
  goBack: 'go-back',
  canGoBack: 'can-go-back',
};

export const deafultFooterConfiguration = [
  {
    title: 'Rewards',
    route: 'home',
    icon: 'https://devassets.fudr.in/brand/freddiestg/Rewards.png',
  },
  {
    title: 'Order',
    route: 'ordering',
    icon: 'https://devassets.fudr.in/brand/freddiestg/Order.png',
  },
  {
    title: 'Locations',
    route: 'locations',
    icon: 'https://devassets.fudr.in/brand/freddiestg/location.png',
  },
  {
    title: 'More',
    route: 'account',
    icon: 'https://devassets.fudr.in/brand/freddiestg/More.png',
  },
];

export const rewardRules = {
  spend: 'SPEND',
  rewardCoupon: 'REWARD_COUPON',
};

export const appOrderingStatusCodes = {
  comingSoon: 'DEMO',
  active: 'ACTIVE',
};

export const rewardTypes = {
  rewardPoint: 'RP',
};
