import { get, union, isEmpty } from 'lodash';

// app level reducer

import {
  PURGE,
  MARK_PERSISTOR_FOR_PURGE,
  DISPATCH_USER_CONSENT,
  FETCH_MASTER_DATA,
  FETCH_RESTAURANT_MENU,
  FETCH_RESTAURANT_DATA,
  FETCH_RESTAURANT_OFFERS,
  FETCH_RESTAURANT_LIST,
  FETCH_AVAILABLE_CITIES,
  UPDATE_MENU,
  FETCH_MENU_ITEM_CUSTOMIZATIONS,
  FETCH_VIEW_CART,
  PLACE_ORDER,
  FETCH_ORDER_STATUS,
  FETCH_ORDERED_ITEMS,
  FETCH_GUEST_ORDERS,
  ATTEMPT_LOGIN_SUCCESS,
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  CLEAR_SELECTED_TABLE,
  CLEAR_UPDATED_SCAN_CODE,
  DISPATCH_SIGNIN_DATA,
  DISPATCH_AUTH_TOKEN,
  GET_CURRENT_ORDER_COUNT,
  API_ERROR_RESPONSE,
  DISABLED_MENU_ITEMS,
  REQUEST_ASSISTANCE,
  DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP,
  FIND_GUEST_LOCATION,
  DISPATCH_VERIFIED_LOCALITIES,
  FETCH_SELECTED_TABLE,
  PREVIOUS_SELECTED_TABLE,
  FETCH_LOCALITIES,
  DISPATCH_SELECTED_ADDRESS,
  DISPATCH_RESTAURANT_GROUP,
  DISPATCH_CUSTOMIZATION_LOADER,
  DISPATCH_USER_CART,
  DISPATCH_PARENT_ROUTE,
  FETCH_AVAILABLE_OFFERS,
  DISPATCH_CONTEXTUAL_HOME,
  CART_UPSELL_ITEMS,
  FETCH_GUEST_CASH_CARDS,
  CLEAR_GUEST_CASH_CARDS,
  FETCH_GUEST_CASH_CARD_DETAILS,
  CLEAR_GUEST_CASH_CARD_DETAILS,
  FETCH_GUEST_CASH_CARD_TRANSACTIONS,
  CLEAR_GUEST_CASH_CARD_TRANSACTIONS,
  FETCH_RESTAURANT_CASH_CARD,
  CLEAR_RESTAURANT_CASH_CARD,
  CREATE_CASH_CARD_RECHARGE,
  CLEAR_CASH_CASH_RECHARGE,
  DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION,
  CLEAR_VERIFIED_LOCALITY,
  DISPATCH_PROGRESSIVE_WEB_APP_DATA,
  CLEAR_PROGRESSIVE_WEB_APP_DATA,
  FETCH_BRAND_DEALS,
  CLEAR_BRAND_DEALS,
  FETCH_SUBSCRIBED_DEAL,
  DISPATCH_GUEST_DATA,
  CLEAR_GUEST_DATA,
  UPDATE_DEAL_GUEST,
  FETCH_BRAND_DEAL_DATA,
  CLEAR_BRAND_DEAL_DATA,
  DISPATCH_GUEST_VISITED_RESTAURANTS,
  FETCH_PREVIOUS_ORDERS,
  CLEAR_PREVIOUS_ORDERS,
  FETCH_OUTLET_DETAILS,
  GUEST_FEEDBACK,
  GET_BRAND_FEEDBACK_SETUP,
  CLEAR_GUEST_FEEDBACK,
  DISPATCH_IS_REWARDS_CLUB_FLOW,
  CREATE_BRAND_GUEST,
  FETCH_MENU_ITEM_VARIATIONS,
  GET_ORDER_FEEDBACK_SETUP,
  FETCH_ORDER_FEEDBACK,
} from '../actions/actionType';

const initialState = {
  persistorMarkedForPurge: false,
};

const app = (state = initialState, action = {}) => {
  if (action.type === PURGE) {
    return initialState;
  } else if (action.type === MARK_PERSISTOR_FOR_PURGE) {
    const { persistorMarkedForPurge } = action;
    return {
      ...state,
      persistorMarkedForPurge,
    };
  } else if (action.type === DISPATCH_USER_CONSENT) {
    const { userConsent } = action;
    return {
      ...state,
      userConsent,
    };
  } else if (action.type === FETCH_MASTER_DATA) {
    const { masterData } = action;
    return {
      ...state,
      masterData,
    };
  } else if (action.type === FETCH_RESTAURANT_MENU) {
    const {
      restaurant,
      selectedTable,
      serviceTypes,
      sections,
      disabledCategories,
      recommendedSections,
    } = action;
    return {
      ...state,
      menu: union(recommendedSections, sections),
      restaurant,
      selectedTable,
      serviceTypes,
      disabledCategories,
      recommendedSections,
    };
  } else if (action.type === DISPATCH_RESTAURANT_GROUP) {
    const { restaurantGroup } = action;
    return {
      ...state,
      restaurantGroup,
    };
  } else if (action.type === FETCH_RESTAURANT_DATA) {
    const { restaurant } = action;
    return {
      ...state,
      restaurant,
    };
  } else if (action.type === FETCH_RESTAURANT_OFFERS) {
    const { restaurantOffers } = action;
    return {
      ...state,
      restaurantOffers,
    };
  } else if (action.type === UPDATE_MENU) {
    const { menu } = action;
    return {
      ...state,
      searchResult: menu,
    };
  } else if (action.type === FETCH_MENU_ITEM_CUSTOMIZATIONS) {
    const { customization } = action;
    return {
      ...state,
      customization,
    };
  } else if (action.type === FETCH_VIEW_CART) {
    const { items } = action;
    return {
      ...state,
      viewCart: items,
    };
  } else if (action.type === PLACE_ORDER) {
    const { orderedItems } = action;
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === FETCH_ORDER_STATUS) {
    let orderedItems = get(state, 'orderedItems', {});
    const { orderStatus } = action;
    orderedItems.statusCode = get(orderStatus, 'status', '');
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === FETCH_ORDERED_ITEMS) {
    const { orderedItems } = action;
    return {
      ...state,
      orderedItems,
    };
  } else if (action.type === ATTEMPT_LOGIN_SUCCESS) {
    const { loginData } = action;
    return {
      ...state,
      loginData,
    };
  } else if (action.type === CLEAR_VERIFIED_LOCALITY) {
    return {
      ...state,
      verifiedLocalities: [],
      selectedAddress: {},
    };
  } else if (action.type === BEGIN_AJAX_CALL) {
    const { showSpinner } = action;
    return {
      ...state,
      showSpinner,
    };
  } else if (action.type === END_AJAX_CALL) {
    const { showSpinner } = action;
    return {
      ...state,
      showSpinner,
    };
  } else if (action.type === CLEAR_SELECTED_TABLE) {
    const { selectedTable } = action;
    return {
      ...state,
      selectedTable,
    };
  } else if (action.type === CLEAR_UPDATED_SCAN_CODE) {
    const { updatedScanCode } = action;
    return {
      ...state,
      updatedScanCode,
    };
  } else if (action.type === DISPATCH_SIGNIN_DATA) {
    const { signIn } = action;
    return {
      ...state,
      signIn,
    };
  } else if (action.type === DISPATCH_AUTH_TOKEN) {
    const { authData } = action;
    return {
      ...state,
      authData,
    };
  } else if (action.type === GET_CURRENT_ORDER_COUNT) {
    const { orderCount } = action;
    return {
      ...state,
      orderCount,
    };
  } else if (action.type === FETCH_GUEST_ORDERS) {
    const { guestOrders } = action;
    return {
      ...state,
      guestOrders,
    };
  } else if (action.type === API_ERROR_RESPONSE) {
    const { errorResponse } = action;
    return {
      ...state,
      errorResponse,
    };
  } else if (action.type === DISABLED_MENU_ITEMS) {
    const { disabledItems } = action;
    return {
      ...state,
      disabledItems,
    };
  } else if (action.type === REQUEST_ASSISTANCE) {
    const { assistanceData, isAssistanceUpdated } = action;
    return {
      ...state,
      assistanceData,
      isAssistanceUpdated,
    };
  } else if (action.type === DISPATCH_REQUESTED_ASSISTANCES_TIMESTAMP) {
    const { requestedAssistancesTimeStampMap } = action;
    return {
      ...state,
      requestedAssistancesTimeStampMap,
    };
  } else if (action.type === FETCH_RESTAURANT_LIST) {
    const { restaurantList } = action;
    return {
      ...state,
      restaurantList,
    };
  } else if (action.type === FETCH_AVAILABLE_CITIES) {
    const { availableCities } = action;
    return {
      ...state,
      availableCities,
    };
  } else if (action.type === FETCH_LOCALITIES) {
    const { localities } = action;
    return {
      ...state,
      localities,
    };
  } else if (action.type === FIND_GUEST_LOCATION) {
    const { guestLocation } = action;
    return {
      ...state,
      guestLocation,
    };
  } else if (action.type === DISPATCH_VERIFIED_LOCALITIES) {
    const { verifiedLocalities } = action;
    return {
      ...state,
      verifiedLocalities,
    };
  } else if (action.type === FETCH_SELECTED_TABLE) {
    const { selectedTable } = action;
    return {
      ...state,
      selectedTable,
    };
  } else if (action.type === PREVIOUS_SELECTED_TABLE) {
    const { previousSelectedTable } = action;
    return {
      ...state,
      previousSelectedTable,
    };
  } else if (action.type === DISPATCH_SELECTED_ADDRESS) {
    const { selectedAddress } = action;
    return {
      ...state,
      selectedAddress,
    };
  } else if (action.type === DISPATCH_CUSTOMIZATION_LOADER) {
    const { showCustomizationLoader } = action;
    return {
      ...state,
      showCustomizationLoader,
    };
  } else if (action.type === DISPATCH_PARENT_ROUTE) {
    const { parentRoute } = action;
    return {
      ...state,
      parentRoute,
    };
  } else if (action.type === DISPATCH_USER_CART) {
    const { cart } = action;
    return {
      ...state,
      cart,
    };
  } else if (action.type === FETCH_AVAILABLE_OFFERS) {
    const { availableOffers } = action;
    return {
      ...state,
      availableOffers,
    };
  } else if (action.type === DISPATCH_CONTEXTUAL_HOME) {
    const { contextualHome } = action;
    return {
      ...state,
      contextualHome,
    };
  } else if (action.type === CART_UPSELL_ITEMS) {
    const { cartUpsellItems } = action;
    return {
      ...state,
      cartUpsellItems,
    };
  } else if (action.type === FETCH_GUEST_CASH_CARDS) {
    const { guestCashCards } = action;
    return {
      ...state,
      guestCashCards,
    };
  } else if (action.type === CLEAR_GUEST_CASH_CARDS) {
    return {
      ...state,
      guestCashCards: {},
    };
  } else if (action.type === FETCH_GUEST_CASH_CARD_DETAILS) {
    const { guestCashCardDetails } = action;
    return {
      ...state,
      guestCashCardDetails,
    };
  } else if (action.type === CLEAR_GUEST_CASH_CARD_DETAILS) {
    return {
      ...state,
      guestCashCardDetails: {},
    };
  } else if (action.type === FETCH_GUEST_CASH_CARD_TRANSACTIONS) {
    const { guestCashCardTransactions } = action;
    return {
      ...state,
      guestCashCardTransactions,
    };
  } else if (action.type === CLEAR_GUEST_CASH_CARD_TRANSACTIONS) {
    return {
      ...state,
      guestCashCardTransactions: {},
    };
  } else if (action.type === FETCH_RESTAURANT_CASH_CARD) {
    const { restaurantCashCard } = action;
    return {
      ...state,
      restaurantCashCard,
    };
  } else if (action.type === FETCH_PREVIOUS_ORDERS) {
    const { previousOrders } = action;
    return {
      ...state,
      previousOrders,
    };
  } else if (action.type === CLEAR_PREVIOUS_ORDERS) {
    return {
      ...state,
      previousOrders: [],
    };
  } else if (action.type === CLEAR_RESTAURANT_CASH_CARD) {
    return {
      ...state,
      restaurantCashCard: [],
    };
  } else if (action.type === CREATE_CASH_CARD_RECHARGE) {
    const { guestCashCardRechargeDetails } = action;
    return {
      ...state,
      guestCashCardRechargeDetails,
    };
  } else if (action.type === CLEAR_CASH_CASH_RECHARGE) {
    return {
      ...state,
      guestCashCardRechargeDetails: {},
    };
  } else if (action.type === DISPATCH_LAST_CASH_CARD_RECHARGE_TRANSACTION) {
    const { lastCashCardRechargeTransaction } = action;
    return {
      ...state,
      lastCashCardRechargeTransaction,
    };
  } else if (action.type === DISPATCH_PROGRESSIVE_WEB_APP_DATA) {
    const { progressiveWebApp } = action;
    return {
      ...state,
      progressiveWebApp,
    };
  } else if (action.type === CLEAR_PROGRESSIVE_WEB_APP_DATA) {
    return {
      ...state,
      progressiveWebApp: {},
    };
  } else if (action.type === DISPATCH_GUEST_DATA) {
    const { guestData } = action;
    return {
      ...state,
      guestData,
    };
  } else if (action.type === CLEAR_GUEST_DATA) {
    return {
      ...state,
      guestData: {},
    };
  } else if (action.type === FETCH_BRAND_DEALS) {
    const { brandDeals } = action;
    return {
      ...state,
      brandDeals,
    };
  } else if (action.type === CLEAR_BRAND_DEALS) {
    return {
      ...state,
      brandDeals: [],
    };
  } else if (action.type === FETCH_SUBSCRIBED_DEAL || action.type === UPDATE_DEAL_GUEST) {
    const { subscribedDeal } = action;
    return {
      ...state,
      subscribedDeal,
    };
  } else if (action.type === FETCH_BRAND_DEAL_DATA) {
    const { brandDeal } = action;
    return {
      ...state,
      brandDeal,
    };
  } else if (action.type === CLEAR_BRAND_DEAL_DATA) {
    return {
      ...state,
      brandDeal: {},
    };
  } else if (action.type === DISPATCH_GUEST_VISITED_RESTAURANTS) {
    const { guestVisitedRestaurants } = action;

    if (isEmpty(state.guestData)) return state;

    return {
      ...state,
      guestData: {
        ...state.guestData,
        visitedRestaurants: guestVisitedRestaurants,
      },
    };
  } else if (action.type === FETCH_OUTLET_DETAILS) {
    const { outletDetails } = action;
    return {
      ...state,
      outletDetails,
    };
  } else if (action.type === GUEST_FEEDBACK) {
    const { guestFeedback } = action;
    return {
      ...state,
      guestFeedback,
    };
  } else if (action.type === GET_BRAND_FEEDBACK_SETUP) {
    const { feedbackSetup } = action;
    return {
      ...state,
      feedbackSetup,
    };
  } else if (action.type === GET_ORDER_FEEDBACK_SETUP) {
    const { feedbackSetup } = action;
    return {
      ...state,
      orderFeedbackSetup: feedbackSetup,
    };
  } else if (action.type === FETCH_ORDER_FEEDBACK) {
    const { feedbackObj } = action;
    return {
      ...state,
      orderFeedbackById: feedbackObj,
    };
  } else if (action.type === CLEAR_GUEST_FEEDBACK) {
    return {
      ...state,
      guestFeedback: {},
    };
  } else if (action.type === DISPATCH_IS_REWARDS_CLUB_FLOW) {
    const { isRewardsClubFlow } = action;
    return {
      ...state,
      isRewardsClubFlow,
    };
  } else if (action.type === CREATE_BRAND_GUEST) {
    const { brandGuest } = action;
    return {
      ...state,
      brandGuest,
    };
  } else if (action.type === FETCH_MENU_ITEM_VARIATIONS) {
    const { variations } = action;
    return {
      ...state,
      variations,
    };
  }
  return state;
};

export default app;
